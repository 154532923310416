<template>
    <section class="index-w">
        <Title text="崇信美食" />
        <div class="food-nav">
            <span @click="typeChange(4)" class="b-item" :class="routerlink == 4 ? 'active' : ''">崇信名吃</span>
            <span @click="typeChange(5)" class="b-item" :class="routerlink == 5 ? 'active' : ''">崇信特产</span>
        </div>
        <div class="food-list">
            <el-row :gutter="14">
                <el-col :xs="8" v-for="item in result" :key="item.id">
                    <router-link :to="{path: '/tourism/detail',query: { id: item.id }}" class="food-item">
                        <img :src="'/jeecg-boot/' + item.theLocal" class="image">
                        <div class="title"><span>{{ item.theTitle }}</span></div>
                    </router-link>
                </el-col>
            </el-row>
        </div>
        <router-link :to="`/tourism/list/${routerlink}`" class="wors-more">查看更多></router-link>
    </section>
</template>

<script>
import Title from './Title'
import { getAction } from "@/api/manage"
export default {
    components: {
        Title
    },
    created() {
        this.typeChange(4)
    },
    data() {
        return {
            result: [],
            routerlink: '4'
        }
    },
    methods: {
        typeChange(type) {
            this.routerlink = type
            let params = {
                theType: type,
                pageSize: 6
            };
            getAction("/culture/cxCulture/list", params).then((res) => {
                if (res.success) {
                    this.result = res.result.records
                }
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.food-nav{
    .b-item{
        color: #999;
        font-size: 1rem;
        margin-right: 1rem;
    }
    .active{
        color: #6EB400;
    }
}
.food-list{
    margin-top: 1rem;
    height: 200px;
    .food-item{
        position: relative;
        margin-bottom: .5rem;
        display: block;
        .image{
            width: 100%;
            height: 5.5rem;
            border-radius: 4pt;
        }
        .title{
            position: absolute;
            left: 0;
            bottom: 4px;
            width: 100%;
            height: 1.3rem;
            line-height: 1.3rem;
            background-color: rgba($color: #000000, $alpha: 0.6);
            color: #fff;
            font-size: .8rem;
            transition: all 0.3s;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-radius:  0 0 4pt 4pt;
            span {
                padding: 0 .5rem;
            }
        }
    }
}
.wors-more{
    color: #999999;
    border: 1px solid #ccc;
    font-size: .8rem;
    padding: .1rem .5rem;
    border-radius: 1rem;
    width: 4rem;
    display: block;
    text-align: center;
    margin: .5rem auto 0;
    &:active{
        color: #fff;
        background-color: #6EB400;
    }
}
</style>