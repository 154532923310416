<template>
    <section class="index-w">
        <Title text="投诉建议" more="/ComplaintList" />
        <div class="sugges-container">
            <ul class="sugges-list">
                <li class="sugges-item clear" v-for="(item,index) in result" :key="item.id">
                    <div v-if="index == 0">
                        <div class="sugges-ask clear">
                            <span class="circle ask">问</span>
                            <router-link :to="{ path: '/complaintdata', query: { id: item.id } }" class="title">{{ item.compReason }}</router-link>
                        </div>
                        <div class="sugges-ans clear">
                            <span class="circle ans">答</span>
                            <router-link :to="{ path: '/complaintdata', query: { id: item.id } }" class="title">{{ item.compReply }}</router-link>
                        </div>
                    </div>
                    <div v-else class="sugges-es">
                        <router-link :to="{ path: '/complaintdata', query: { id: item.id } }" class="title">{{ item.compReason }}</router-link>
                    </div>
                </li>
            </ul>
        </div>
        <router-link to="/complaint" class="complain-btn">我要投诉</router-link>
    </section>
</template>

<script>
import Title from "./Title";
import { getAction } from "@/api/manage";
export default {
  components: {
    Title,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      result: [],
    };
  },
  methods: {
    loadData() {
      let prame = {
        pageSize: 3,
      };
      getAction("/complaint/tourComplaint/openList", prame).then((res) => {
        if (res.success) {
          this.result = res.result.records;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sugges-container {
  background-color: #f5f5f5;
  .sugges-list {
    padding: 1rem;
    .sugges-item {
      .sugges-ask {
        margin-bottom: 1rem;
      }
      .sugges-ans {
        margin-bottom: 1rem;
      }
      .sugges-es {
        border-bottom: 1px solid #cccccc;
      }
      .title {
        display: block;
        color: #999;
        line-height: 2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .circle {
        display: block;
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        color: #fffefe;
        font-size: 1rem;
        border-radius: 50%;
        float: left;
        margin-right: 8px;
      }
      .ask {
        background-color: #6EB400;
      }
      .ans {
        background-color: #1aa4fb;
      }
    }
  }
}
.complain-btn{
    background-color: #6EB400;
    color: #fff;
    display: block;
    width: 61pt;
    height: 24pt;
    line-height: 24pt;
    text-align: center;
    font-size: 1rem;
    margin: 1rem auto;
    border-radius: 4pt;
}
</style>
