<template>
    <section class="header-container">
        <!-- <header class="top-header">
            <img src="@/assets/image/main/logo.png" class="wap-logo lf">
            <div class="right-user lr">
                <i class="el-icon-user-solid"></i>
            </div>
        </header> -->
        <section class="swiper-container wap-wheel-container">
            <div class="swiper-wrapper">
                <div v-for="item in tp" :key="item.id" class="swiper-slide">
                    <a :href="item.theLink" target="_blank">
                        <img :src="'/jeecg-boot/' + item.theLocal" class="banner-img" />
                    </a>
                </div>
            </div>
        </section>
        <div>
            <el-input
                placeholder="目的地/景点"
                suffix-icon="el-icon-search"
                class="wap-search"
                v-model="queryvalue"
                 @click.native="querykeyword"
                 @keyup.enter.native="querykeyword"
                >
            </el-input>
        </div>
        <!-- 导航菜单 -->
        <div class="wap-menu">
            <el-row>
                <el-col :xs="6" v-for="(item,index) in menu" :key="index">
                    <router-link :to="item.url" class="menu-item">
                        <img :src="item.image" class="menu-icon">
                        <span class="menu-name">{{ item.name }}</span>
                    </router-link>
                </el-col>
            </el-row>
        </div>
    </section>
</template>

<script>
import Swiper from "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.min.css";
import { getAction } from '@/api/manage'
export default {
    mounted() {
        new Swiper(".wap-wheel-container", {
            autoplay: 5000,
            paginationClickable: true,
            spaceBetween: '6%',
            nextButton: ".swiper-button-next",
            prevButton: ".swiper-button-prev",
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true, //修改swiper的父元素时，自动初始化swiper
        });
        this.loadDataTP();
    },
    data() {
        return {
            queryvalue: '',
            tp:{},
            menu: [
                {name: '崇信概况', url: '/survey', image: '/image/wap/icon/icon1.png'},
                {name: '景点与路线', url: '/scenicspot', image: '/image/wap/icon/icon2.png'},
                {name: '旅游文化', url: '/tourism/list/1', image: '/image/wap/icon/icon3.png'},
                {name: '旅游管理', url: '/tourismmana', image: '/image/wap/icon/icon4.png'},
                {name: '旅游企业', url: '/hotel', image: '/image/wap/icon/icon5.png'},
                {name: '信息查询', url: '/touristinfo', image: '/image/wap/icon/icon6.png'},
                {name: '最新游记', url: '/latesttravel', image: '/image/wap/icon/icon7.png'},
                {name: '虚拟旅游', url: '/strategy', image: '/image/wap/icon/icon8.png'},
            ]
        };
    },
    methods:{
        loadDataTP(){
            this.getAction("/lbt/sysLbt/list").then((res) => {
                if (res.success) {
                    this.tp = res.result.records;
                }
            });
        },
        querykeyword() {
        if(this.queryvalue==''){
            console.log('请输入搜索内容')
        }else{
            this.$router.push({
                path: '/retrieval',
                query: {
                    keyword: this.queryvalue
                }
            })
        }
    },
    }
}
</script>

<style lang="scss" scoped>
.header-container{
    .top-header{
        height: 35pt;
        padding: 8px 7pt 0;
        background-color: #6EB400;
        .wap-logo{
            width: 55%;
        }
        .right-user{
            color: #fff;
            font-size: 24px;
            margin-top: 4pt;
        }
    }
    .wap-wheel-container{
        margin: 14pt 0;
        padding: 0 5%;
        .swiper-slide{
            border-radius: 8px;
            padding-bottom: 10px;
            .banner-img{
                width: 100%;
                height: 134pt;
                border-radius: 8px;
                box-shadow: 3px 5px 5px #C4C4C4;
            }
        }
    }
    .wap-search{
        width: 90%;
        margin: 0 auto;
        display: block;
        ::v-deep.el-input__inner{
            box-shadow: 0 1pt 4pt #c7c7c7;
            border-color: #EEE;
        }
    }
    .wap-menu{
        margin-top: 16px;
        background-color: #F8FEF2;
        padding: 1.2rem 0 0;
        .menu-item{
            text-align: center;
            display: block;
            margin-bottom: 1.2rem;
            .menu-icon{
                width: 54px;
                height: 54px;
                margin: 0 auto;
                display: block;
                margin-bottom:  .4rem;
            }
            .menu-name{
                display: block;
                color: #333333;
            }
        }
    }
}
</style>
