<template>
    <div class="wap-container">
        <Headers />
        <!-- 顶部轮播图和检索 -->
        <Rotation />
        <div class="wap-strip"></div>
        <!-- 热门景点 -->
        <Hotspots />
        <div class="wap-strip"></div>
        <!-- 导航 -->
        <Navgroup />
        <div class="wap-strip"></div>
        <!-- 崇信文化 -->
        <Worship />
        <div class="wap-strip"></div>
        <!-- 崇信美食 -->
        <Cxfood />
        <div class="wap-strip"></div>
        <!-- 首页图片导航 -->
        <Imageurl />
        <div class="wap-strip"></div>
        <!-- 最新游记 -->
        <Latest />
        <div class="wap-strip"></div>
        <!-- 投诉建议 -->
        <Sugges />
        <Footer />
    </div>
</template>

<script>
import Headers from '@/components/wap/Headers'

/* 顶部轮播图和检索 */
import Rotation from './index/Rotation'
/* 热门景点 */
import Hotspots from './index/Hotspots'
/* 导航 */ 
import Navgroup from './index/Navgroup'
/* 崇信文化 */ 
import Worship from './index/Worship'
/* 崇信美食 */ 
import Cxfood from './index/Cxfood'
/* 首页图片导航 */
import Imageurl from './index/Imageurl'
/* 最新游记 */
import Latest from './index/Latest'
/* 投诉建议 */
import Sugges from './index/Sugges'

import Footer from '@/components/main/Footer'
export default {
    components: {
        Headers,
        /* 顶部轮播图和检索 */
        Rotation,
        /* 热门景点 */
        Hotspots,
        /* 导航 */ 
        Navgroup,
        /* 崇信文化 */ 
        Worship,
        /* 崇信美食 */
        Cxfood,
        /* 首页图片导航 */
        Imageurl,
        /* 最新游记 */
        Latest,
        /* 投诉建议 */
        Sugges,

        Footer
    },
    data() {
        return {
            
        }
    },
}
</script>

<style lang="scss" scoped>
.wap-strip{
    background-color: #F9F9F9;
    height: 16pt;
    width: 100%;
}
</style>