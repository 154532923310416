<template>
    <section class="index-w">
        <img src="/image/participate.png" class="wap-in-image" />
    </section>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.wap-in-image{
    width: 100%;
}
</style>