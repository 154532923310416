<template>
    <div class="wap-index-title clear">
        <div class="text lf">{{ text }}</div>
        <router-link v-if="more" class="more lr" :to="more" >查看更多></router-link>
    </div>
</template>

<script>
export default {
    props: ['text','more'],
    data() {
        return {
            
        }
    },
}
</script>

<style lang="scss" scoped>
.wap-index-title{
    margin-bottom: 16px;
    .text{
        font-size: 1.5rem;
        color: #333;
        &::before{
            content: '';
            display: block;
            background-color: #6EB400;
            width: .25rem;
            height: 1.5rem;
            float: left;
            margin: 6px 5px 0 0;
            border-radius: 2pt;
        }
    }
    .more{
        color: #999;
        margin-top: 0.7rem;
    }
}
</style>