<template>
    <section class="index-w">
        <Title text="热门景点" more="/scenicspot" />
        <div class="waphot-container">
            <el-row :gutter="13">
                <el-col :xs="24">
                    <div class="waphot-first">
                        <router-link :to="{path: '/scenicDetail',query: { id: bigending.id },}">
                            <img :src="`/jeecg-boot/${bigending.theLocal}`" class="im" />
                            <div class="title">
                                <span>{{ bigending.theTitle }}</span>
                            </div>
                        </router-link>
                    </div>
                </el-col>
                <el-col :xs="12" v-for="item in smallending" :key="item.id">
                    <div class="waphot-second">
                        <router-link :to="{path: '/scenicDetail',query: { id: item.id },}">
                            <img :src="`/jeecg-boot/${item.theLocal}`" class="im" />
                            <div class="title">
                                <span>{{ item.theTitle }}</span>
                            </div>
                        </router-link>
                    </div>
                </el-col>
            </el-row>
        </div>
    </section>
</template>

<script>
import Title from './Title'
import { getAction } from "@/api/manage"
export default {
    components: {
        Title
    },
    created() {
        this.loadData();
    },
    data() {
        return {
            result: [],
        };
    },
    computed: {
		bigending(){
            if(this.result.length != 0){
                return this.result[0]
            }
        },
        smallending(){
            if(this.result.length != 0){
                let arr = []
                for (let i = 0; i < this.result.length; i++) {
                    if(i > 0){
                        arr.push(this.result[i])
                    }
                }
                return arr
            }
        }
    },
    methods: {
        loadData() {
			let params = {
				column: 'createTime',
				order: 'desc',
				pageSize: 3,
			}
            getAction("/scenery/tourScenery/list",params).then((res) => {
                if (res.success) {
					this.result = res.result.records
                }
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.waphot-container{
    .waphot-first{
        position: relative;
        .im{
            width: 100%;
            height: 132pt;
            border-radius: 4pt;
        }
        .title{
            position: absolute;
            left: 0;
            bottom: 5px;
            width: 100%;
            height: 24pt;
            line-height: 24pt;
            background-color: rgba($color: #000000, $alpha: 0.6);
            color: #fff;
            font-size: 12pt;
            transition: all 0.3s;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-radius:  0 0 4pt 4pt;
            span {
                padding: 0 15px;
            }
        }
    }
    .waphot-second{
        margin-top: .6rem;
        position: relative;
        .im{
            width: 100%;
            height: 114pt;
            border-radius: 4pt;
        }
        .title{
            position: absolute;
            left: 0;
            bottom: 5px;
            width: 100%;
            height: 24pt;
            line-height: 24pt;
            background-color: rgba($color: #000000, $alpha: 0.6);
            color: #fff;
            font-size: 12pt;
            transition: all 0.3s;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-radius:  0 0 4pt 4pt;
            span {
                padding: 0 15px;
            }
        }
    }
}
</style>