<template>
    <section class="index-w">
        <div class="navgroup-container">
            <ul class="navgroup-list clear">
                <li class="navgroup-item" v-for="(item,index) in list" :key="index">
                    <router-link :to="item.url" class="clear">
                        <img :src="item.image" class="n-icon">
                        <div class="n-texe">
                            <p class="name">{{ item.name }}</p>
                            <p class="en">{{ item.en }}</p>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {name: '饭店',en: 'Hotel',image: '/image/wap/icon/hotel_icon.png',url: '/hotel'},
                {name: '门票',en: 'Admission Ticket',image: '/image/wap/icon/ticket_icon.png',url: '/ticketprice'},
                {name: '旅行社',en: 'Travel Bureau',image: '/image/wap/icon/travel_icon.png',url: '/travelagency'},
                {name: '娱乐',en: 'Entertainment',image: '/image/wap/icon/enter_icon.png',url: '/entertainment'},
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.navgroup-container{
    .navgroup-list{
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        &::-webkit-scrollbar {
            display: none;
        }  
        .navgroup-item{
            flex: 0 0 10rem;
            margin-right: 11pt;
            padding: 13px 9px;
            box-shadow: 0 1px 8px #e8e8e8;
            border-radius: 4px;
            margin: .4rem;
            .n-icon{
                float: left;
                margin-right: .5rem;
            }
            .n-texe{
                float: left;
                .name{
                    color: #333;
                    font-size: 1rem;
                    margin-bottom: .3rem;
                }
                .en{
                    color: #999;
                    font-size: .8rem;
                }
            }
        }
    }
}
</style>