<template>
    <section class="index-w">
        <Title text="最新游记" more="/latesttravel" />
        <div class="latest-container">
            <ul class="latest-list clear">
                <li class="latest-item" v-for="item in result" :key="item.id">
                    <router-link :to="{ path: '/latestdata', query: { id: item.id } }">
                        <img :src="`/jeecg-boot/${item.theLocal}`" class="im">
                        <div class="title">{{ item.theTitle }}</div>
                    </router-link>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import Title from './Title'
import { getAction } from "@/api/manage"
export default {
    components: {
        Title
    },
    created() {
        this.loadData();
    },
    data() {
        return {
            result: [],
        }
    },
    methods: {
        loadData() {
            let params = {
                pageSize: 4,
            };
            getAction("/note/tourNote/listAllNote", params).then((res) => {
                if (res.success) {
                    this.result = res.result.records;
                }
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.latest-container{
    .latest-list{
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        &::-webkit-scrollbar {
            display: none;
        }  
        .latest-item{
            flex: 0 0 16rem;
            margin-right: 1rem;
            border-radius: 4px;
            .im{
                width: 16rem;
                border-radius: 4px;
                height: 10rem;
            }
            .title{
                width: 16rem;
                font-size: 1rem;
                color: #333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
</style>